<template>
    <div class="video-player">
      <video ref="videoPlayer" :src="videoSrc" controls @timeupdate="onTimeUpdate" @ended="onEnded"></video>
      <div class="controls">
      </div>

      <iframe allow="autoplay *; encrypted-media *; fullscreen *; clipboard-write" frameborder="0" height="450" style="width:100%;max-width:660px;overflow:hidden;border-radius:10px;" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-storage-access-by-user-activation allow-top-navigation-by-user-activation" src="https://embed.podcasts.apple.com/cn/podcast/ted-tech/id470624027"></iframe>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        videoSrc: 'http://192.168.31.11:9000/english/story/%E3%80%901000%E9%9B%86%E5%85%A8%E3%80%91B%E7%AB%99%E6%9C%80%E5%85%A8%21%E5%90%8C%E5%AD%A6%E4%BB%AC%E4%BD%A0%E8%BF%98%E4%B8%8D%E8%BF%9B%E6%9D%A5%21%E5%90%AC%E5%8A%9B%E5%B9%B2%E8%B4%A7%EF%BC%8C%E7%B2%BE%E9%80%89%E8%8B%B1%E6%96%87%E5%8A%A8%E7%94%BB%E7%B3%BB%E5%88%97%E6%95%85%E4%BA%8B%E7%9C%8B%E6%95%85%E4%BA%8B%E5%AD%A6%E8%8B%B1%E8%AF%AD%20P1%20%E3%80%90001%E3%80%91%E6%B0%B8%E8%BF%9C%E4%B8%8D%E8%A6%81%E6%94%BE%E5%BC%83%E8%87%AA%E5%B7%B1%EF%BC%88%E4%B8%80%E4%B8%AA22%E5%B2%81%E8%BF%98%E5%9C%A8%E4%B8%8A%E9%AB%98%E4%B8%AD%E7%9A%84%E4%BA%BA%E7%9A%84%E5%BB%BA%E8%AE%AE%EF%BC%89.mp4?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=magichouse%2F20241001%2F%2Fs3%2Faws4_request&X-Amz-Date=20241001T110749Z&X-Amz-Expires=432000&X-Amz-SignedHeaders=host&X-Amz-Signature=11c9bb70311bb557d7a205ac0fc2618223327d883a39ccbe99bac32cc5bdbab8', // 替换为你的视频文件路径
        currentTime: 0,
        duration: 0,
      };
    },
    methods: {
      playPause() {
        const video = this.$refs.videoPlayer;
        if (video.paused || video.ended) {
          video.play();
        } else {
          video.pause();
        }
      },
      stop() {
        const video = this.$refs.videoPlayer;
        video.pause();
        video.currentTime = 0;
        this.currentTime = 0;
      },
      seekTo(time) {
        const video = this.$refs.videoPlayer;
        video.currentTime = time;
      },
      onTimeUpdate() {
        const video = this.$refs.videoPlayer;
        this.currentTime = video.currentTime;
      },
      onEnded() {
        this.stop();
      },
      formatTime(seconds) {
        const date = new Date(null);
        date.setSeconds(seconds);
        return date.toISOString().substr(11, 8);
      }
    },
    mounted() {
      const video = this.$refs.videoPlayer;
      video.addEventListener('loadedmetadata', () => {
        this.duration = video.duration;
      });
    }
  };
  </script>
  
  <style scoped>
  .video-player {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .controls {
    margin-top: 10px;
    display: flex;
    align-items: center;
  }
  
  .controls button {
    margin-right: 10px;
  }
  
  .controls input[type="range"] {
    width: 300px;
    margin-right: 10px;
  }
  </style>