import Vue from 'vue';
import VueRouter from 'vue-router';

// 导入你的组件
import Home from '../views/HomeComponent.vue';
import Login from '../views/LoginComponent.vue';
import Video from '../views/VideoPlayerComponent.vue';
import ted from '../views/TedComponent.vue';

Vue.use(VueRouter);

const routes = [
  {
    title: "home",
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    title: "word",
    path: '/word/:word',
    name: 'searchWord',
    component: Home
  },
  {
    title: "login",
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    title: "video",
    path: '/video',
    name: 'Video',
    component: Video
  },
  {
    title: "ted",
    path: '/ted',
    name: 'Ted',
    component: ted
  },

  // 其他路由配置
];

const router = new VueRouter({
  mode: 'history', // 使用 HTML5 History 模式
  base: process.env.BASE_URL,
  routes
});

export default router;