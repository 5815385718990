<template>
  <div>
    <div class="sticky-header">
      <button @click="nextVocabulary">下一个</button>
      <button @click="killVocabulary">学会了</button>
      <button @click="knownVocabulary">忘记了</button>
      <button @click="summary">不学了</button>
      <div class="container">
        <input type="text" v-model="query" @keyup.enter="search" placeholder="search a word or ask a question">
      </div>

      <div id="audio">
        <h2>{{ word }}</h2>
        <audio :src="audioUrl" controls></audio>
      </div>
    </div>

    <div class="content">
      <div v-html="previewHtml" id="preview"></div>
    </div>
  </div>
</template>

<script>
import { nextVocabulary, killVocabulary, summary, searchVocabulary, knownVocabulary } from '@/services/api';
import marked from 'marked';

export default {
  name: 'HomeComponent',
  data() {
    return {
      search_history: [],
      title: 'Magic Notebook',
      word: '',
      previewHtml: '',
      audioUrl: '',
      query: '',
    };
  },
  methods: {
    search() {
      if (this.query.trim()) {
        const keyword = this.query;
        searchVocabulary(keyword).then(data => {
          if (data.success) {
            this.search_history.push(keyword);
            this.fillData(data);
          }
        }).catch(error => {
          console.error('Error searching vocabulary:', error);
        });
      } else {
        alert('请输入查询内容');
      }
    },
    summary() {
      summary().then(data => {
        if (data.success) {
          this.fillData(data);
        }
      }).catch(error => {
        console.error('Error fetching summary:', error);
      });
    },
    killVocabulary() {
      const currentWord = this.word;
      console.log("currentWord:", currentWord);
      killVocabulary(currentWord).then(data => {
        if (data.success) {
          this.nextVocabulary();
        }
      }).catch(error => {
        console.error('Error killing vocabulary:', error);
      });
    },
    knownVocabulary() {
      const currentWord = this.word;
      console.log("currentWord:", currentWord);
      knownVocabulary(currentWord).then(data => {
        if (data.success) {
          this.nextVocabulary();
        }
      }).catch(error => {
        console.error('Error killing vocabulary:', error);
      });
    },
    nextVocabulary() {
      nextVocabulary().then(data => {
        if (data.success) {
          this.fillData(data);
        }
      }).catch(error => {
        console.error('Error fetching next vocabulary:', error);
      });
    },
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    },
    fillData(data) {
      const { title, content } = data.result;
      console.log("content="+content)
      const markdown = JSON.parse(content).content;
      const html = marked(markdown);
      this.audioUrl = `http://dict.youdao.com/dictvoice?type=0&audio=${title}`;
      this.word = title;
      this.previewHtml = html;
      document.title = title;
      this.scrollToTop();
      if (this.query !== title) {
        this.query = "";
      }
      // 如果当前路由中的单词和返回值不一致，则更新路径
      if (this.$route.params.word !== this.word) {
        this.$router.replace(`/word/${this.word}`);
      }
    }
  },
  mounted() {
    const word = this.$route.params.word;
    if (word) {
      this.query = word;
      this.search();
    } else if (this.word === '') {
      this.nextVocabulary();
    }
  },
  watch: {
    '$route.params.word'(newWord) {
      // 当新的单词不等于当前显示的单词时再执行搜索
      if (newWord && newWord !== this.word) {
        this.query = newWord;
        this.search();
      }
    }
  }
};
</script>

<style scoped>
/* 你的样式代码 */
</style>